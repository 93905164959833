import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import youtube from '../images/youtube.svg';
import instagram from '../images/instagram.svg';
import email from '../images/email.svg';

import Header from './header';
import 'bootstrap-4-grid/css/grid.min.css';

const Footer = styled.footer`
text-align: center;
color: var(--sycamore);
margin-bottom: 50px;
`;

const SocialMediaLinksList = styled.ul`
list-style: none;
display: flex;
justify-content: center;
padding: 0;
margin: 0 0 50px;
`;

const SocialMediaLinkLink = styled.a`
margin: 0 5px;
`;

const Site = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Site>
      <GlobalStyles />
      <div className="spring">
        <Header siteTitle={data.site.siteMetadata.title || 'Title'} />
      </div>
      <main>{children}</main>
      <div className="spring">
        <Footer>
          <SocialMediaLinksList>
            <li>
              <SocialMediaLinkLink href="https://www.youtube.com/channel/UCHTpssq2a6R9gic2_5Xywqg" target="_blank">
                <img src={youtube} alt="Follow us on Youtube" />
              </SocialMediaLinkLink>
            </li>
            <li>
              <SocialMediaLinkLink href="https://www.instagram.com/thegoodtree.faith/" target="_blank">
                <img src={instagram} alt="Follow us on Instagram" />
              </SocialMediaLinkLink>
            </li>
            <li>
              <SocialMediaLinkLink href="mailto:thegoodtree.faith@gmail.com">
                <img src={email} alt="Email us" />
              </SocialMediaLinkLink>
            </li>
          </SocialMediaLinksList>
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          The Good Tree
        </Footer>
      </div>
    </Site>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
