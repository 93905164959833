import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import bismillah from '../images/bismillah.svg';
import logo from '../images/the-good-tree-logo.svg';
import apple from '../images/apple.svg';
import google from '../images/google.svg';
import spotify from '../images/spotify.svg';

const BismillahImage = styled.img`
  margin: 40px;
`;

const Logo = styled.img`
  width: 250px;
  margin-bottom: 20px;
`;

const HeaderComponent = styled.header`
  text-align: center;
  margin-bottom: 100px;
`;

const PodcastProvidersList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  @media (min-width: 768px) {
    margin: 0 0 50px;
  }
`;

const PodcastProviderLink = styled.a`
  margin: 0 16px;
  @media (min-width: 768px) {
    color: #767676;
    align-items: center;
    display: flex;
    margin: 0 30px;
    text-decoration: none;
  }
`;

const PodcastProviderImage = styled.img`
  margin-right: 10px;
`;

const PodcastProviderName = styled.span`
  display: none;
  @media (min-width: 768px) {
    display: inline-block
  }
`;

const ListenHeading = styled.h2`
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;

const Header = ({ siteTitle }) => (
  <HeaderComponent>
    <BismillahImage src={bismillah} alt="" />
    <h1><Logo src={logo} alt={siteTitle} /></h1>
    <ListenHeading>
      Listen &amp; subscribe
      {' '}
      <span className="sr-only">to The Good Tree Podcast</span>
    </ListenHeading>
    <PodcastProvidersList>
      <li>
        <PodcastProviderLink href="https://podcasts.apple.com/gb/podcast/the-good-tree-podcast/id1538063872" target="_blank">
          <PodcastProviderImage src={apple} alt="Listen on Apple Podcasts" />
          <PodcastProviderName>Apple Podcasts</PodcastProviderName>
        </PodcastProviderLink>
      </li>
      <li>
        <PodcastProviderLink href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xNDMzNDc5LnJzcw==" target="_blank">
          <PodcastProviderImage src={google} alt="Listen on Google Podcasts" />
          <PodcastProviderName>Google Podcasts</PodcastProviderName>
        </PodcastProviderLink>
      </li>
      <li>
        <PodcastProviderLink href="https://open.spotify.com/show/0GZzAP4uUxsGlIuTqUQh1O" target="_blank">
          <PodcastProviderImage src={spotify} alt="Listen on Spotify" />
          <PodcastProviderName>Spotify</PodcastProviderName>
        </PodcastProviderLink>
      </li>
    </PodcastProvidersList>
  </HeaderComponent>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
