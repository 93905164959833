import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Amaranth&display=swap");

  :root {
    --sushi: #9FA740;
    --sycamore: #8C9340;
  }
  html {
    font-size: 1rem;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: "Open Sans";
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  .spring {
    max-width: 1230px;
    padding: 0 15px;
    min-width: 320px;
    margin: 0 auto;
  }
  h1,
  h2,
  h3 {
    color: var(--sycamore);
    margin-top: 0;
    font-family: "Amaranth";
    font-weight: 300;
  }

  p {
    margin-top: 0;
  }

  .content-light {
    color: #fff;
  }
`;

export default GlobalStyles;
